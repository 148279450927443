import { Button } from "@mui/material";
import { styled } from "@mui/system";
const ButtonPrimary = styled(Button)({
  background: "#ffb100",
  textTransform: "unset",
  // ':hover': {
  //   color: "#ffb100",
  //   backgroundColor: 'white',
  // },
});
export default ButtonPrimary;
