
import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { headers } from "../../constants";
import { loginSuccess } from "../../slice/loginSlice";

import './agenda.css';
import './banner.css';

interface LoginProps {
  setUserData: any;
}

const Login3 = ({ setUserData }: LoginProps) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [errFirstName, setErrFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errLastName, setErrLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [errCompanyName, setErrCompanyName] = useState("");

  //
  const dispatch = useDispatch();

  const validateForm = () => {
    let res = true;
    if (firstName.trim().length === 0) {
      setErrFirstName("First name is required!");
      res = false;
    }
    if (lastName.trim().length === 0) {
      setErrLastName("Last name is required!");
      res = false;
    }
    if (email.trim().length === 0) {
      setErrEmail("Email is required!");
      res = false;
    }
    if (companyName.trim().length === 0) {
      setErrCompanyName("Company name is required!");
      res = false;
    }

    // Check if email follows a valid format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email.trim())) {
      setErrEmail("Invalid email format!");
      res = false;
    }

    // Check if email ends with restricted domains
    const emailDomain = email.trim().split("@")[1];
    const restrictedDomains = [
      "gmail.com",
      "yahoo.com",
      "hotmail.com",
      "aol.com",
    ];

    if (restrictedDomains.some((domain) => emailDomain.endsWith(domain))) {
      setErrEmail("Registration with this email domain is not allowed.");
      res = false;
    }

    return res;
  };
  const handleClickSignUp = async () => {
    if (validateForm()) {
      const requestData = {
        email,
        firstName,
        lastName,
        companyName,
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/login2`,
          requestData,
          {
            headers: headers,
          }
        );
        if (response.data.status === 200 || response.data.status === 201) {
          dispatch(
            loginSuccess({
              userData: response?.data?.userData,
              accessToken: response?.data?.token,
            })
          );
          setUserData(response?.data?.userData);
          navigate("/");
        }
      } catch (error) {
        console.error("error:", error);
      }
    }
  };
  return (
    <>
      {/* <!-- BANNER SECTION --> */}
      <section id="dwn">
        <div className="land-full land-3-banner-full">
          <div className="land-container">

            <div className="land-3-banner">
              <div className="land-3-banner-conte">
                <h1 className="fade-in one"><span className="tit1">Free Tool for</span> Empowering Your Customer Development Process</h1>
                <p className="fade-in two">
                Are you ready to supercharge your customer discovery process?
                </p>
              </div>

              {/* <!-- BOOKING FORM --> */}
              <div className="land-3-banner-form fade-in four">
                <div className="enquiry-right land-com-form">
                  <h2>Get HugeIdea For Free</h2>
                  <form id="frmLogin" className="contact__form" method="post" action="mail.php">
                    <div className="alert alert-success contact__msg" style={{ display: "none" }} role="alert">
                      Your message was sent successfully.
                    </div>
                    <ul style={{ marginBottom: '15px' }}>
                      <li style={{ display: 'inline' }}>
                        <input type="text" name="firstName" placeholder="First Name" style={{ width: '49%', display: 'inherit' }}
                          onChange={(e) => {
                            setErrFirstName("");
                            setFirstName(e.target.value);
                          }}
                        />
                        <input type="text" name="lastName" placeholder="Last Name" style={{ width: '49%', display: 'inherit', float: 'right' }}
                          onChange={(e) => {
                            setErrLastName("");
                            setLastName(e.target.value);
                          }} /></li>
                      {errFirstName && (
                        <p className="error">
                          {errFirstName}
                        </p>
                      )}
                      {errLastName && (
                        <p className="error">
                          {errLastName}
                        </p>
                      )}
                    </ul>
                    <ul>
                      <li>
                        <input type="text" name="email" placeholder="Business Email" onChange={(e) => {
                          setErrEmail("");
                          setEmail(e.target.value);
                        }} />
                        {errEmail && (
                          <p className="error">
                            {errEmail}
                          </p>
                        )}
                      </li>
                      <li>
                        <input type="text" name="companyName" placeholder="Company Name" onChange={(e) => {
                          setErrCompanyName("");
                          setCompanyName(e.target.value);
                        }} />
                        {errCompanyName && (
                          <p className="error">
                            {errCompanyName}
                          </p>
                        )}
                      </li>
                      <li>
                        <input id="btnSubmit" type="button" name="submit" value="Next" onClick={handleClickSignUp} />
                      </li>
                      <li>
                        <p>By submitting your agree with our <a href="#">Privacy Policy</a></p>
                      </li>
                    </ul>
                  </form>
                </div>
              </div>
            </div>
            <div className="mov-cloud">
              <div className="x1">
                <div className="cloud"></div>
              </div>
              <div className="x2">
                <div className="cloud"></div>
              </div>

              <div className="x3">
                <div className="cloud"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="land-full land-4-agen-full">
          <div className="land-container">
            <div className="land-3-agen">
              <div className="land-3-agen-left">
                <h2>How Can HugeIdea Help You?</h2>
              </div>
              <div className="land-3-agen-right">
                <ul>
                  <li>
                    <h4>Time Saving and Cost Cutting</h4>
                    <p>HugeIdea’s AI-based Canvas is a freemium tool to help founders and entrepreneurs cut time by 50% on canvas modeling, and cost by 60-70% in IP protection.</p>
                  </li>
                  <li>
                    <h4>Innovation Acceleration</h4>
                    <p>Accelerate their innovation process by creating a draft set of canvas hypothesis to improve understanding of their target customers' needs, pains, and gains to create more effective value propositions.</p>
                  </li>
                  <li>
                    <h4>IP Protection</h4>
                    <p>Teaches importance of IP protection of Go-To-Market strategies and then automate IP protection when hypothesis is confirmed.</p>
                  </li>
                  <li>
                    <h4>Empower The Entrepreneurs</h4>
                    <p>Save time and effort, allowing them to focus on other critical aspects of their business. </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="land-full vid-full">
          <div className="land-container">
            <div className="vid-sec">
              <div className="vid-sec-lhs" style={{ width: '100%' }}>
                <h2>Try HugeIdea Today – It's Free and Easy!</h2>
                <p>Try HugeIdea and unlock the potential for growth and success. Don't wait – try HugeIdea  now!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Login3;