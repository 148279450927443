import { createSlice } from "@reduxjs/toolkit";

export interface LoginState {
  accessToken: string;
  userData: any;
  isModalOpen: boolean;
}

const initialState: LoginState = {
  accessToken: "",
  userData: {},
  isModalOpen: false,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      if (action?.payload?.userData) {
        state.userData = action?.payload?.userData;
        localStorage.setItem("user_id", action?.payload?.userData.id);
      }
      if (action?.payload?.accessToken) {
        localStorage.setItem("access_token", action?.payload?.accessToken);
        state.accessToken = action?.payload?.accessToken;
      }
    },
    loginFail: (state) => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user_id");
      state.accessToken = "";
      state.userData = {};
    },
    logout: (state) => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user_id");
      state.accessToken = "";
      state.userData = {};
    },
    openModal: (state) => {
      state.isModalOpen = true;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
    },
  },
});

export const { loginSuccess, loginFail, logout, openModal, closeModal } = loginSlice.actions;

export const selectIsModalOpen = (state: any) => state.login.isModalOpen;

export default loginSlice.reducer;
