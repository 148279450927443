import React from "react";
import { Grid, TextField, Box, Button } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import "./style.css";

const BasicInfoForm = ({ nextStep, handleChange, formData, formErrors }) => {
  const continueStep = (e) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <Box
      component="div"
      sx={{
        // padding: { xs: "0.5rem 8px", sm: "1rem 20px" },
        backgroundColor: "#fff",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              padding: { xs: "0.5rem 8px", sm: "1rem 20px" },
              backgroundColor: "#fff",
            }}
          >
            <Box
              component="h2"
              fontWeight={500}
              mb={4}
              color="var(--main-content-text-color)"
            >
              HugeIdea AI will generate your initial project
            </Box>
            <Box
              component="div"
              mb={2}
              textAlign="left"
              color="var(--main-active-color)"
              sx={{
                fontWeight: 500,
                marginTop: "20px",
              }}
            >
              Please fill out the following fields to get a jump start on your
              business idea
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              "& ul": {
                listStyleType: "auto",
              },
              "& li": {
                position: "relative",
                paddingLeft: "20px",
                paddingTop: "8px",
                "&::before": {
                  content: "'\\2022'",
                  position: "absolute",
                  left: "0",
                  color: "var(--main-content-text-color)",
                },
              },
              "& h4": {
                fontWeight: "normal",
              },
              "& .title": {
                display: "flex",
                alignItems: "center",
              },
              "& .star-icon": {
                color: "#FFC107",
                marginRight: "3px",
                marginBottom: "-4px",
              },
            }}
          >
            <Box
              component="div"
              mb={2}
              color="var(--main-content-text-color)"
              sx={{
                padding: { xs: "0.5rem 8px", sm: "1rem 20px" },
                backgroundColor: "#e4d7fb9c",
                borderRadius: "4px",
              }}
            >
              <h3 className="my-heading-star">
                <StarIcon className="star-icon" />
                Describe your business
                <StarIcon className="star-icon" />
              </h3>
              <h4>
                For best results answer the following questions about your
                business:
              </h4>
              <ul>
                <li>What problem are you solving?</li>
                <li>What is the solution to the problem?</li>
                <li>What products or services does the business offer?</li>
                <li>Who are your customers?</li>
              </ul>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box  sx={{ padding: "0"}}>
        <TextField
          label="Project name"
          variant="outlined"
          value={formData.product_name}
          onChange={handleChange("product_name")}
          error={!!formErrors.product_name}
          helperText={formErrors.product_name}
          size="small"
          inputProps={{ style: { height: "40px", padding: "8px" } }}
          InputLabelProps={{
            style: {
              fontSize: "12px",
              color: "#c1c3ca",
              fontWeight: "600",
              textTransform: "uppercase",
            },
          }}
          sx={{ width: "100%", marginBottom: "18px" }}
        />
        <TextField
          label="Description (Minimum 100 characters)"
          variant="outlined"
          inputProps={{
            style: { height: "auto", padding: "8px" },
            minLength: 100,
          }}
          InputLabelProps={{
            style: {
              fontSize: "12px",
              color: "#c1c3ca",
              fontWeight: "600",
              textTransform: "uppercase",
            },
          }}
          value={formData.product_description}
          onChange={handleChange("product_description")}
          error={!!formErrors.product_description}
          helperText={formErrors.product_description}
          multiline
          rows={4}
          size="small"
          sx={{ width: "100%", marginBottom: "18px" }}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Who are the customers who will buy or use the product?"
              variant="outlined"
              value={formData.customers}
              onChange={handleChange("customers")}
              error={!!formErrors.customers}
              helperText={formErrors.customers}
              size="small"
              inputProps={{ style: { height: "40px", padding: "8px" } }}
              InputLabelProps={{
                style: {
                  fontSize: "12px",
                  color: "#c1c3ca",
                  fontWeight: "600",
                  textTransform: "uppercase",
                },
              }}
              sx={{ width: "100%", marginBottom: "18px" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Why do customers buy your product?"
              variant="outlined"
              value={formData.usage_reason}
              onChange={handleChange("usage_reason")}
              error={!!formErrors.usage_reason}
              helperText={formErrors.usage_reason}
              size="small"
              inputProps={{ style: { height: "40px", padding: "8px" } }}
              InputLabelProps={{
                style: {
                  fontSize: "12px",
                  color: "#c1c3ca",
                  fontWeight: "600",
                  textTransform: "uppercase",
                },
              }}
              sx={{ width: "100%", marginBottom: "18px" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="What are your known direct competitors?"
              variant="outlined"
              value={formData.competitors}
              onChange={handleChange("competitors")}
              error={!!formErrors.competitors}
              helperText={formErrors.competitors}
              size="small"
              inputProps={{ style: { height: "40px", padding: "8px" } }}
              InputLabelProps={{
                style: {
                  fontSize: "12px",
                  color: "#c1c3ca",
                  fontWeight: "600",
                  textTransform: "uppercase",
                },
              }}
              sx={{ width: "100%", marginBottom: "18px" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="How do you make money with the product?"
              variant="outlined"
              value={formData.make_money_way}
              onChange={handleChange("make_money_way")}
              error={!!formErrors.make_money_way}
              helperText={formErrors.make_money_way}
              size="small"
              inputProps={{ style: { height: "40px", padding: "8px" } }}
              InputLabelProps={{
                style: {
                  fontSize: "12px",
                  color: "#c1c3ca",
                  fontWeight: "600",
                  textTransform: "uppercase",
                },
              }}
              sx={{ width: "100%", marginBottom: "18px" }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              label="Do you have any go to market strategy?"
              variant="outlined"
              value={formData.gtm_strategy}
              onChange={handleChange("gtm_strategy")}
              error={!!formErrors.gtm_strategy}
              helperText={formErrors.gtm_strategy}
              size="small"
              inputProps={{ style: { height: "40px", padding: "8px" } }}
              InputLabelProps={{
                style: {
                  fontSize: "12px",
                  color: "#c1c3ca",
                  fontWeight: "600",
                  textTransform: "uppercase",
                },
              }}
              sx={{ width: "100%", marginBottom: "18px" }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BasicInfoForm;
