import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
  IconButton,
  Grid,
  Link,
} from "@mui/material";
import BasicInfoForm from "../../components/Forms/BasicInfoForm";
import ConfirmationForm from "../../components/Forms/ConfirmationForm";
import CheckIPForm from "../../components/Forms/CheckIPForm";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ButtonTransparent from "../../components/ButtonTransparent";
import CustomTextField from "../../components/CustomTextField";
import axios from "axios";
import jsPDF from "jspdf";
import ButtonPrimary from "../../components/ButtonPrimary";
import cheerio from "cheerio";
import { useNavigate, useLocation } from "react-router-dom";
axios.defaults.timeout = 100000;

function HomePage() {
  const [pdfPath, setPdfPath] = useState("");
  const [webpages, setWebpages] = useState([""]);
  const [links, setLinks] = useState([""]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [value, setValue] = useState("");
  const [bLinks, setBLinks] = useState([""]);
  const [bSelectedFiles, setBSelectedFiles] = useState([]);
  const [bValue, setBValue] = useState("");
  const [data, setData] = useState({});
  const componentRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState(location.state?.step || 1);
  const [formData, setFormData] = useState(
    location.state?.formData || {
      product_name: "",
      product_description: "",
      customers: "",
      usage_reason: "",
      competitors: "",
      make_money_way: "",
      gtm_strategy: "",
    }
  );

  const [formErrors, setFormErrors] = useState({
    product_name: "",
    product_description: "",
    customers: "",
    usage_reason: "",
    competitors: "",
    make_money_way: "",
    gtm_strategy: "",
  });
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [businessCanvasData, setBusinessCanvasData] = useState(
    location.state?.businessCanvasData || null
  );
  useEffect(() => {
    console.log("pdfPath:", pdfPath);
    console.log("webpages:", webpages);

    const encodedData = {
      pdf_path: pdfPath,
      webpages: webpages,
    };

    Object.keys(formData).forEach((key) => {
      const htmlString = formData[key];
      const $ = cheerio.load(htmlString);
      encodedData[key] = $.text();
    });
  }, [pdfPath, webpages, formData]);

  useEffect(() => {
    if (location.state) {
      setStep(location.state.step || 1);
      setFormData(location.state.formData || formData);
      setBusinessCanvasData(
        location.state.businessCanvasData || businessCanvasData
      );
    }
  }, [location.state]);

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
    setFormErrors({ ...formErrors, [input]: "" });
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!formData.product_name.trim()) {
      errors.product_name = "Please enter project name";
      isValid = false;
    }

    if (!formData.product_description.trim()) {
      errors.product_description = "Please enter description";
      isValid = false;
    } else if (formData.product_description.trim().length < 100) {
      errors.product_description =
        "Description must be at least 100 characters";
      isValid = false;
    }
    if (
      !formData.customers.trim() ||
      !formData.usage_reason.trim() ||
      !formData.competitors.trim() ||
      !formData.make_money_way.trim() ||
      !formData.gtm_strategy.trim()
    ) {
      errors.customers =
        errors.usage_reason =
        errors.competitors =
        errors.make_money_way =
        errors.gtm_strategy =
          "Please enter data";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const nextStep = () => {
    if (step === 1) {
      if (validateForm()) {
        setStep(step + 1);
      }
    } else if (step === 2) {
      if (validateForm()) {
        handleSubmit();
      }
    } else {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading(true);
      const user_id = localStorage.getItem("user_id");
      const requestData = {
        ...formData,
        user_id: user_id,
      };
      let updatedPdfPath = "";
      let updatedWebpages = [];
      try {
        setLoading(true);
        if (selectedFiles.length > 0 || links.length > 0) {
          const formData = new FormData();
          for (const file of selectedFiles) {
            formData.append("files", file);
          }
          for (const link of links) {
            formData.append("links", link);
          }

          if (bSelectedFiles.length > 0 || bLinks.length > 0) {
            for (const bFile of bSelectedFiles) {
              formData.append("bFiles", bFile);
            }
            for (const bLink of bLinks) {
              formData.append("bLinks", bLink);
            }
          }
          try {
           
            setData({});
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/upload/upload-files?user_id=${user_id}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${localStorage.getItem(
                    "access_token"
                  )}`,
                },
              }
            );

            const allWebpages = [...links, ...bLinks];
            setWebpages(allWebpages);
            updatedWebpages = [...links, ...bLinks];
            if (
              updatedWebpages.length === 0 ||
              updatedWebpages.every((page) => page === "")
            ) {
              updatedWebpages = [""];
            }
            if (response.status === 200) {
              setSelectedFiles([]);
              setLinks([]);
              setBSelectedFiles([]);
              setBLinks([]);
              setValue("");
              setBValue("");
              // toast.success(response?.data?.message, toastConfig);
              setData(response.data);
              setPdfPath(response.data.path_folder || "");
              updatedPdfPath = response.data.path_folder || "";
              
            } else {
              setPdfPath("");
            }
           
          } catch (error) {
            setPdfPath("");
          }
        } else {
          setPdfPath("");
          setWebpages([]);
        }

        const encodedData = {
          pdf_path: updatedPdfPath,
          webpages: updatedWebpages,
          ...requestData,
        };

        // Object.keys(requestData).forEach((key) => {
        //   const htmlString = requestData[key];
        //   const $ = cheerio.load(htmlString);
        //   encodedData[key] = $.text();
        // });

        const apiUrl = process.env.REACT_APP_AI_API_URL;
        const nextApiResponse = await axios.post(
          `${apiUrl}/generateBusinessModelCanvas`,
          encodedData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );

        if (nextApiResponse.status === 200) {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/product/submit-form`,
            requestData,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            }
          );

          if (response.status === 200) {
            setBusinessCanvasData(nextApiResponse.data.response);
            console.log('setBusinessCanvasData',nextApiResponse.data.response);
            setStep(step + 1);
            setLoading(false);
          } else {
            console.error("Error:", response.data.message);
            setModalMessage(response.data.message);
            setModalOpen(true);
          }
        } else {
          console.error("Error:", nextApiResponse.data.message);
          setModalMessage(nextApiResponse.data.message);
          setModalOpen(true);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
        setModalMessage(
          "Due to an unexpectedly high volume of requests, the system is currently overloaded. We kindly ask you to try again later. Thank you for your understanding."
        );
        setModalOpen(true);
        setLoading(false);
      }
    }
  };

  //step 3
  const handleInputChange = (e) => {
    setValue(e.target.value);
    const newItems = [...links];
    newItems[0] = e.target.value;
    setLinks(newItems);
  };

  const handleBInputChange = (e) => {
    setBValue(e.target.value);
    const newItems = [...bLinks];
    newItems[0] = e.target.value;
    setBLinks(newItems);
    console.log("newItems", newItems);
  };

  const handleAddLink = () => {
    setValue("");
    setLinks((links) => ["", ...links]);
  };

  const handleAddBLink = () => {
    setBValue("");
    setBLinks((bLinks) => ["", ...bLinks]);
    console.log("links");
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
    e.target.value = "";
  };

  const handleBFileChange = (e) => {
    const files = Array.from(e.target.files);
    setBSelectedFiles(files);
    e.target.value = "";
  };

  const handleDeleteItem = (index, setFunction, array, type = "") => {
    const newArray = [...array];
    newArray.splice(index, 1);

    if (type === "link") {
      setLinks(newArray);
    } else if (type === "bLink") {
      setBLinks(newArray);
    } else if (type === "file") {
      setSelectedFiles(newArray);
    } else if (type === "bFile") {
      setBSelectedFiles(newArray);
    } else {
      setFunction(newArray);
    }
  };
  // const handleSubmitUpload = async () => {
  //   if (selectedFiles.length > 0 || links.length > 0) {
  //     const formData = new FormData();
  //     for (const file of selectedFiles) {
  //       formData.append("files", file);
  //     }
  //     for (const link of links) {
  //       formData.append("links", link);
  //     }

  //     if (bSelectedFiles.length > 0 || bLinks.length > 0) {
  //       for (const bFile of bSelectedFiles) {
  //         formData.append("bFiles", bFile);
  //       }
  //       for (const bLink of bLinks) {
  //         formData.append("bLinks", bLink);
  //       }
  //     }
  //     try {
  //       setLoading(true);
  //       setData({});
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_API_URL}/test/submit-form`,
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //             Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  //           },
  //         }
  //       );

  //       if (response.status === 200) {
  //         setSelectedFiles([]);
  //         setLinks([]);
  //         setBSelectedFiles([]);
  //         setBLinks([]);
  //         setValue("");
  //         setBValue("");
  //         toast.success(response?.data?.message, toastConfig);
  //         setData(response.data);
  //         console.log("data", response.data);
  //         setStep(step + 1);
  //       } else {
  //         console.error("Upload failed with status:", response.status);
  //         toast.error(
  //           "Failed to upload files. Please try again later.",
  //           toastConfig
  //         );
  //       }
  //       setLoading(false);
  //     } catch (error) {
  //       console.error("Error uploading files:", error);
  //       toast.error(
  //         "Failed to upload files. Please try again later.",
  //         toastConfig
  //       );
  //       setLoading(false);
  //     }
  //   } else {
  //     // Handle case where neither files nor links are selected
  //     setStep(step + 1);
  //     //toast.error("Please select at least one file or one link!", toastConfig);
  //   }
  // };

  const toastConfig = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  };

  // export pdf
  const handlePrint = () => {
    const componentNode = componentRef.current.cloneNode(true);

    componentNode.style.width = 900 + "px";
    let jsPdf = new jsPDF("p", "px", "letter");

    const opt = {
      callback: function (jsPdf) {
        jsPdf.save("BusinessCanvas.pdf");
      },
      margin: [20, 20, 20, 20],
      autoPaging: "text",
      html2canvas: {
        allowTaint: true,
        dpi: 300,
        letterRendering: true,
        logging: false,
        scale: 0.465,
      },
    };

    jsPdf.html(componentNode, opt);
  };

  const percentage = Math.floor((step / 4) * 100);

  return (
    <Box
      className="main-border py-4 mt-4"
      sx={{ padding: "25px", backgroundColor: "#fff" }}
    >
      {step === 1 && (
        <BasicInfoForm
          nextStep={nextStep}
          handleChange={handleChange}
          formData={formData}
          formErrors={formErrors}
        />
      )}

      {step === 2 && (
        <Box component="div" ref={componentRef} id="business-canvas-container">
          <Typography
            variant="h5"
            fontWeight={500}
            mb={4}
            color="var(--main-content-text-color)"
          >
            Input
          </Typography>
          <Typography
            mb={2}
            textAlign="left"
            color="var(--main-content-text-color)"
          >
            Description (please upload your document(s) or specify web page
            link(s) that have your product features or product description)
          </Typography>
          <Grid container spacing={3} justifyContent="start">
            <Grid item xs={6}>
              <Box display="flex" gap="1rem">
                <CustomTextField
                  id="link"
                  variant="outlined"
                  size="small"
                  placeholder="Enter your link here..."
                  inputProps={{ maxLength: 500 }}
                  style={{ minWidth: "25vw" }}
                  value={value}
                  onChange={handleInputChange}
                />
                <ButtonTransparent
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={handleAddLink}
                >
                  <AddIcon />
                  <span style={{ marginLeft: "4px" }}>Add more</span>
                </ButtonTransparent>
              </Box>
              {links.map(
                (link, index) =>
                  link.length > 0 && (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={1}
                      style={{
                        color: "var(--main-content-text-color)",
                        borderBottom:
                          "1px solid var(--main-content-text-color)",
                        fontSize: "14px",
                      }}
                    >
                      <Box
                        component="div"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {link}
                      </Box>
                      <IconButton
                        onClick={() =>
                          handleDeleteItem(index, setLinks, links, "link")
                        }
                      >
                        <DeleteOutlineIcon
                          style={{ color: "#000000", fontSize: "20px" }}
                        />
                      </IconButton>
                    </Box>
                  )
              )}
            </Grid>
            <Grid item xs={6}>
              <input
                id="file-upload"
                type="file"
                multiple
                hidden
                onChange={handleFileChange}
                accept=".pdf"
              />

              <Link
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "40px",
                }}
                component="button"
                variant="body2"
                onClick={() => document.getElementById("file-upload").click()}
              >
                <FileUploadIcon />
                <span style={{ marginLeft: "4px" }}>
                  Upload Files (PDF Only)
                </span>
              </Link>

              {selectedFiles.map((file, index) => (
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={1}
                  style={{
                    color: "var(--main-content-text-color)",
                    borderBottom: "1px solid var(--main-content-text-color)",
                    fontSize: "14px",
                  }}
                >
                  <Box component="div">{file.name}</Box>
                  <IconButton
                    onClick={() =>
                      handleDeleteItem(
                        index,
                        setSelectedFiles,
                        selectedFiles,
                        "file"
                      )
                    }
                  >
                    <DeleteOutlineIcon
                      style={{ color: "#000000", fontSize: "20px" }}
                    />
                  </IconButton>
                </Box>
              ))}
            </Grid>
          </Grid>

          <Typography
            component="div"
            mb={2}
            mt={2}
            textAlign="left"
            color="var(--main-content-text-color)"
          >
            Background (optional- please upload your document/pitch deck or
            specify web page links that have your product background and/or your
            mission statement)
          </Typography>
          <Grid container spacing={3} justifyContent="start">
            <Grid item xs={6}>
              <Box display="flex" gap="1rem">
                <CustomTextField
                  id="bLink"
                  variant="outlined"
                  size="small"
                  placeholder="Enter your link here..."
                  inputProps={{ maxLength: 500 }}
                  style={{ minWidth: "25vw" }}
                  value={bValue}
                  onChange={handleBInputChange}
                />
                <ButtonTransparent
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={handleAddBLink}
                >
                  <AddIcon />
                  <span style={{ marginLeft: "4px" }}>Add more</span>
                </ButtonTransparent>
              </Box>
              {bLinks.map(
                (bLink, index) =>
                  bLink.length > 0 && (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={1}
                      style={{
                        color: "var(--main-content-text-color)",
                        borderBottom:
                          "1px solid var(--main-content-text-color)",
                        fontSize: "14px",
                      }}
                    >
                      <Box
                        component="div"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {bLink}
                      </Box>
                      <IconButton
                        onClick={() =>
                          handleDeleteItem(index, setBLinks, bLinks, "bLink")
                        }
                      >
                        <DeleteOutlineIcon
                          style={{ color: "#000000", fontSize: "20px" }}
                        />
                      </IconButton>
                    </Box>
                  )
              )}
            </Grid>
            <Grid item xs={6}>
              <input
                id="bfile-upload"
                type="file"
                multiple
                hidden
                onChange={handleBFileChange}
                accept=".pdf"
              />

              <Link
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "40px",
                }}
                component="button"
                variant="body2"
                onClick={() => document.getElementById("bfile-upload").click()}
              >
                <FileUploadIcon />
                <span style={{ marginLeft: "4px" }}>
                  Upload Files (PDF Only)
                </span>
              </Link>

              {bSelectedFiles.map((bFile, index) => (
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={1}
                  style={{
                    color: "var(--main-content-text-color)",
                    borderBottom: "1px solid var(--main-content-text-color)",
                    fontSize: "14px",
                  }}
                >
                  <Box component="div">{bFile.name}</Box>
                  <IconButton
                    onClick={() =>
                      handleDeleteItem(
                        index,
                        setBSelectedFiles,
                        bSelectedFiles,
                        "bFile"
                      )
                    }
                  >
                    <DeleteOutlineIcon
                      style={{ color: "#000000", fontSize: "20px" }}
                    />
                  </IconButton>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Box>
      )}

      {step === 3 && (
        <>
          <ConfirmationForm
            prevStep={prevStep}
            formData={formData}
            isLastStep={false}
            componentRef={componentRef}
            businessCanvasData={businessCanvasData}
            nextStep={nextStep}
            handleSubmit={handleSubmit}
          />
        </>
      )}

      {step === 4 && (
        <CheckIPForm
          prevStep={prevStep}
          businessCanvasData={businessCanvasData}
          data={data}
        />
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box className="flex items-center md:w-6 py-4">
          <Box className="uppercase tracking-wide text-xs font-bold text-gray-500 mb-1 leading-tight">
            {step} of 4
          </Box>
          <Box className="w-full bg-purple rounded-full mr={2}">
            <Box
              className="rounded-full bg-purple-500 text-xs leading-none h-2 text-center text-white"
              style={{ width: `${(step / 4) * 100}%` }}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {step > 1 && (
            <Button
              variant="outlined"
              startIcon={<ArrowBackIosNewIcon />}
              onClick={prevStep}
              sx={{ marginRight: "10px" }}
            >
              Back
            </Button>
          )}

          {step === 3 && (
            <>
              <Button
                variant="contained"
                endIcon={<PictureAsPdfIcon />}
                onClick={handlePrint}
                sx={{
                  backgroundColor: "#5840fe",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#291e99",
                  },
                  marginRight: "10px",
                }}
              >
                Export PDF
              </Button>
              <ButtonPrimary
                sx={{
                  backgroundColor: "#0062cc",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#291e99",
                  },
                }}
                disabled={loading}
                variant="contained"
                onClick={nextStep}
              >
                <Box
                  component="div"
                  display="flex"
                  gap={1}
                  alignItems="center"
                  sx={{ textTransform: "uppercase"}}
                >
                  Check Your Ips
                  {loading && (
                    <CircularProgress
                      size={18}
                      sx={{ color: "var(--main-bg-color)" }}
                    />
                  )}
                </Box>
              </ButtonPrimary>
            </>
          )}

          {(step === 1 || step === 2) && (
            <ButtonPrimary
              sx={{
                backgroundColor: "#5840fe",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#291e99",
                },
              }}
              disabled={loading}
              variant="contained"
              onClick={nextStep}
            >
              <Box
                component="div"
                display="flex"
                gap={1}
                alignItems="center"
                sx={{ textTransform: "uppercase" }}
              >
                Continue
                {loading && (
                  <CircularProgress
                    size={18}
                    sx={{ color: "var(--main-bg-color)" }}
                  />
                )}
              </Box>
            </ButtonPrimary>
          )}
        </Box>
      </Box>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <IconButton
            onClick={handleModalClose}
            sx={{ position: "absolute", top: 0, right: 0 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Notification
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {modalMessage}
          </Typography>
        </Box>
      </Modal>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
    </Box>
  );
}

export default HomePage;
