import HomePage from "../pages/HomePage";
import SignUp from "../pages/SignUp";
import Login from "../pages/Login";
import RouterPath from "./RouterPath";
import { RouteConfig } from "./types";
import CheckYourIps from "../pages/CheckYourIps";
export const routesConfig: RouteConfig[] = [
  {
    path: RouterPath.HOME,
    component: HomePage,
    isProtected: true,
  },
   {
     path: RouterPath.REGISTER,
     component: SignUp,
     isProtected: false,
   },
   {
     path: RouterPath.LOGIN,
     component: Login,
     isProtected: false,
   },
   {
    path: RouterPath.CHECKYOURIPS,
    component: CheckYourIps,
    isProtected: false,
  },
];
