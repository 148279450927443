import React from "react";
import { Grid, Box } from "@mui/material";
import partnerships from "../../assets/icons/partnerships.png";
import activities from "../../assets/icons/activities.png";
import resources from "../../assets/icons/resources.png";
import propositions from "../../assets/icons/propositions.png";
import relationships from "../../assets/icons/relationships.png";
import segments from "../../assets/icons/segments.png";
import channels from "../../assets/icons/channels.png";
import structure from "../../assets/icons/structure.png";
import streams from "../../assets/icons/streams.png";
const handleRemoveSpecialCharacter = (str) => {
  return str?.replaceAll("**", "")?.replace(/#/g, "");
};
const renderBoxContent = (content) => {
  return content.split('\n').map((item, index) => <li key={index} style={{ paddingBottom: "5px" }}>{handleRemoveSpecialCharacter(item)}</li>);
};

const BusinessCanvas = ({ data }) => {
  return (
    <Box sx={{ marginTop: "20px", width: "100%", paddingLeft: "0" }}>
      <Grid container spacing={2} sx={{ width: "100%", marginLeft: "0", border: "2px solid" }}>
        {/* Row 1 */}
        <Grid item xs={12} md={2.4} className="my-custom-grid-item">
          <Box sx={{ display: "flex", flexDirection: "column", ...boxStyle, height: "100%" }}>
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
              <h5 className="my-heading-star" style={{ margin: 0 }}>Key Partnerships</h5>
              <Box sx={{ flex: 1, textAlign: "right" }}>
                <img className="star-icon" src={partnerships} alt="Icon" />
              </Box>
            </Box>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {data && renderBoxContent(data.box1)}
            </ul>
          </Box>
        </Grid>

        <Grid item xs={12} md={2.4} className="my-custom-grid-item">
          <Box sx={{ display: "flex", flexDirection: "column", ...boxStyle, height: "100%", padding: "0", paddingTop: "15px" }}>
            {/* Box 2 */}
            <Box className="box-two" sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
              <h5 className="my-heading-star" style={{ margin: 0 }}>Key Activities</h5>
              <Box sx={{ flex: 1, textAlign: "right" }}>
                <img className="star-icon" src={activities} alt="Icon" />
              </Box>
            </Box>
            <ul style={{ listStyleType: "none", paddingBottom: "10px" }} className="box-two">
              {data && renderBoxContent(data.box2)}
            </ul>

            {/* Box 3 */}
            <Box className="box-two" sx={{ display: "flex", alignItems: "center", marginBottom: "10px", paddingTop: "15px", borderTop: "1px solid #ccc" }}>
              <h5 className="my-heading-star" style={{ margin: 0 }}>Key Resources</h5>
              <Box sx={{ flex: 1, textAlign: "right" }}>
                <img className="star-icon" src={resources} alt="Icon" />
              </Box>
            </Box>
            <ul style={{ listStyleType: "none", paddingBottom: "10px" }} className="box-two">
              {data && renderBoxContent(data.box3)}
            </ul>
          </Box>
        </Grid>

        <Grid item xs={12} md={2.4} className="my-custom-grid-item">
          <Box sx={{ display: "flex", flexDirection: "column", ...boxStyle, height: "100%" }}>
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
              <h5 className="my-heading-star" style={{ margin: 0 }}>Value Propositions</h5>
              <Box sx={{ flex: 1, textAlign: "right" }}>
                <img className="star-icon" src={propositions} alt="Icon" />
              </Box>
            </Box>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {data && renderBoxContent(data.box4)}
            </ul>
          </Box>
        </Grid>

        <Grid item xs={12} md={2.4} className="my-custom-grid-item">
          <Box sx={{ display: "flex", flexDirection: "column", ...boxStyle, height: "100%", padding: "0", paddingTop: "15px" }}>
            {/* Box 5 */}
            <Box className="box-two" sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
              <h5 className="my-heading-star" style={{ margin: 0 }}>Customer Relationships</h5>
              <Box sx={{ flex: 1, textAlign: "right" }}>
                <img className="star-icon" src={relationships} alt="Icon" />
              </Box>
            </Box>
            <ul style={{ listStyleType: "none", paddingBottom: "10px" }} className="box-two">
              {data && renderBoxContent(data.box5)}
            </ul>

            {/* Box 6 */}
            <Box className="box-two" sx={{ display: "flex", alignItems: "center", marginBottom: "10px", paddingTop: "15px", borderTop: "1px solid #ccc" }}>
              <h5 className="my-heading-star" style={{ margin: 0 }}>Channels</h5>
              <Box sx={{ flex: 1, textAlign: "right" }}>
                <img className="star-icon" src={channels} alt="Icon" />
              </Box>
            </Box>
            <ul style={{ listStyleType: "none", paddingBottom: "10px" }} className="box-two">
              {data && renderBoxContent(data.box6)}
            </ul>
          </Box>
        </Grid>

        <Grid item xs={12} md={2.4} className="my-custom-grid-item">
          <Box sx={{ display: "flex", flexDirection: "column", ...boxStyle, height: "100%" }}>
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
              <h5 className="my-heading-star" style={{ margin: 0 }}>Customer Segments</h5>
              <Box sx={{ flex: 1, textAlign: "right" }}>
                <img className="star-icon" src={segments} alt="Icon" />
              </Box>
            </Box>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {data && renderBoxContent(data.box7)}
            </ul>
          </Box>
        </Grid>

        {/* Row 2 */}
        <Grid item xs={12} md={6} className="my-custom-grid-item">
          <Box sx={{ display: "flex", flexDirection: "column", ...boxStyle, height: "100%" }}>
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
              <h5 className="my-heading-star" style={{ margin: 0 }}>Cost Structure</h5>
              <Box sx={{ flex: 1, textAlign: "right" }}>
                <img className="star-icon" src={structure} alt="Icon" />
              </Box>
            </Box>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {data && renderBoxContent(data.box8)}
            </ul>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} className="my-custom-grid-item">
          <Box sx={{ display: "flex", flexDirection: "column", ...boxStyle, height: "100%" }}>
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
              <h5 className="my-heading-star" style={{ margin: 0 }}>Revenue Streams</h5>
              <Box sx={{ flex: 1, textAlign: "right" }}>
                <img className="star-icon" src={streams} alt="Icon" />
              </Box>
            </Box>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {data && renderBoxContent(data.box9)}
            </ul>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const boxStyle = {
  border: "1px solid #ccc",
  padding: "15px",
};

export default BusinessCanvas;
