import React, { useRef }from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../assets/images/hugeidea.png";
import userAvatar from "../../assets/images/user-avatar.jpg";
import Login from "../Login/Login";
import {
  logout,
  loginSuccess,
  openModal,
  closeModal,
  selectIsModalOpen,
} from "../../slice/loginSlice";

const PATENTPC_HOST = "https://app.hugeidea.com/";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(500px + 5vw)",
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
};

interface HeaderProps {
  onClickDarkMode: any;
  userData: any;
  onLogout: any;
}

function HeaderPatentPC({ onClickDarkMode, userData, onLogout }: HeaderProps) {
 

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenLoginModal = () => {
    dispatch(openModal());
  };
  const handleCloseLoginModal = () => {
    dispatch(closeModal());
  };
  const userDataRef = useRef(userData); 
  const handleLoginSuccess = (userData:any) => {
    dispatch(loginSuccess(userData));
    handleCloseLoginModal();
    userDataRef.current = userData;
  };

  const updatedUserData = userDataRef.current;
  const userDataToUse = userData?.lastName ? userData : updatedUserData;
  const isModalOpen = useSelector(selectIsModalOpen);
  const handleClickLogout = () => {
    handleCloseUserMenu();
    dispatch(logout());
    onLogout();
    navigate("/login");
    userDataRef.current = userData;
    const userDataToUse = "";
  };

  return (
    
    <AppBar
      position="static"
      sx={{ background: "inherit", border: "none", boxShadow: "none" }}
    > 
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component="img"
            className="w-logo"
            sx={{
              height: { xs: "36px", md: "48px" },
            }}
            alt="PatentPC1"
            src={Logo}
          />
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="default"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
              color="black"
            >
              <MenuItem
                component="a"
                href="https://calendly.com/powerpatent/patentpc"
                key="schedule-a-call"
                onClick={handleCloseNavMenu}
              >
                <Typography textAlign="center">Schedule a Call</Typography>
              </MenuItem>
            </Menu>
            {userDataToUse?.lastName ? (
              <>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar src={userAvatar} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem
                    key={userDataToUse?.lastName + userDataToUse?.firstName}
                    onClick={handleCloseUserMenu}
                  >
                    <Typography textAlign="center">
                      {userDataToUse?.lastName + " " + userDataToUse?.firstName}
                    </Typography>
                  </MenuItem>
                  <MenuItem key={"mniLogout"} onClick={handleClickLogout}>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Box sx={{ display: "flex" }}>
                <>
                  <Button
                    key="login-btn"
                    onClick={handleOpenLoginModal}
                    sx={{ my: 2, color: "black", display: "block" }}
                  >
                    Login
                  </Button>
                  <Button
                    key="register-btn"
                    href="/register"
                    sx={{ my: 2, color: "black", display: "block" }}
                  >
                    Sign up
                  </Button>
                </>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          ></Box>
 
          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            <Button
              key="schedule-call"
              href="https://calendly.com/powerpatent/patentpc"
              target="_blank"
              sx={{
                my: 2,
                mr: 2,
                color: "white",
                backgroundColor: "#ffb100",
                display: "none",
                ":hover": { backgroundColor: "#ffb100" },
              }}
            >
              Schedule a Call
            </Button>
          
            {userDataToUse?.lastName ? (
              <>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar src={userAvatar} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem
                    key={userDataToUse?.lastName + userDataToUse?.firstName}
                    onClick={handleCloseUserMenu}
                  >
                    <Typography textAlign="center">
                      {userDataToUse?.lastName + " " + userDataToUse?.firstName}
                    </Typography>
                  </MenuItem>
                  <MenuItem key={"mniLogout"} onClick={handleClickLogout}>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <Button
                  key="login-btn"
                  onClick={handleOpenLoginModal}
                  sx={{ my: 2, color: "black", display: "block" }}
                >
                  Login
                </Button>
                <Button
                  key="register-btn"
                  href="/register"
                  sx={{ my: 2, color: "black", display: "block" }}
                >
                  Sign up
                </Button>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>

      <Modal open={isModalOpen} onClose={() => dispatch(closeModal())}>
        <Box sx={modalStyle}>
          <Login setUserData={handleLoginSuccess} />
        </Box>
      </Modal>
    </AppBar>
  );
}

export default HeaderPatentPC;
