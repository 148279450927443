import { Box, Typography } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setIsOpenDialogMessage } from "../../slice/dialogMessageSlice";
import { loginSuccess } from "../../slice/loginSlice";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "../../components/ButtonPrimary";
import CustomTextField from "../../components/CustomTextField";
import CustomTypography from "../../components/CustomTypography";
import { headers } from "../../constants";
import { closeModal } from "../../slice/loginSlice";
import HeaderPatentPC from "../../components/HeaderPatentPC";
interface LoginProps {
  setUserData: any;
}

const Login = ({ setUserData }: LoginProps) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const dispatch = useDispatch();
  const validateForm = () => {
    let isValid = true;
    if (email.trim().length === 0) {
      setErrEmail("Email is required!");
      isValid = false;
    }
    if (password.trim().length === 0) {
      setErrPassword("Password is required!");
      isValid = false;
    }
    return isValid;
  };

  const handleRegisterClick = () => {
    dispatch(closeModal());
  };

  const handleClickLogin = async () => {
    if (validateForm()) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/login`,
          { email, password },
          { headers }
        );
      
        if (response?.data?.status === 200) {
          dispatch(closeModal());
          dispatch(
            loginSuccess({
              userData: response?.data?.userData,
              accessToken: response?.data?.token,
            })
          );
          setUserData(response?.data?.userData);
          navigate("/");
        } else if (response?.data?.status === 403) {
          setErrMessage("Email and password do not match!");
        } else if (response?.data?.status === 404) {
          setErrMessage("Account does not exist!");
        } else {
          setErrMessage("An unexpected error occurred. Please try again.");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <Box
      component="div"
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        p: 4,
        borderRadius: 2,
      }}
    >
      <Box component="div" display="flex" justifyContent="center" mb={2}>
        <CustomTypography
          color="text.primary"
          fontSize="24px !important"
          fontWeight="700 !important"
        >
          SIGN IN
        </CustomTypography>
      </Box>
      <Box component="div" mb={2}>
        <CustomTextField
          id="email"
          label="Email"
          variant="outlined"
          inputProps={{ maxLength: 50 }}
          fullWidth
          value={email}
          onChange={(e) => {
            setErrEmail("");
            setEmail(e.target.value);
          }}
        />
        <Typography
          variant="caption"
          color={errEmail.length > 0 ? "error" : "transparent"}
          sx={{ marginTop: "4px" }}
        >
          {errEmail.length > 0 ? errEmail : "error"}
        </Typography>
      </Box>
      <Box component="div" mb={2}>
        <CustomTextField
          id="password"
          label="Password"
          variant="outlined"
          inputProps={{ maxLength: 15 }}
          type="password"
          fullWidth
          value={password}
          onChange={(e) => {
            setErrPassword("");
            setPassword(e.target.value);
          }}
        />
        <Typography
          variant="caption"
          color={errPassword.length > 0 ? "error" : "transparent"}
        >
          {errPassword.length > 0 ? errPassword : "error"}
        </Typography>
      </Box>
      {errMessage && (
        <Typography
          variant="caption"
          color="error"
          sx={{ marginBottom: "10px", display: "block", textAlign: "center", fontSize:"13px" }}
        >
          {errMessage}
        </Typography>
      )}
      <Box component="div" mb={2}>
        <ButtonPrimary variant="contained" fullWidth onClick={handleClickLogin}>
          Log In
        </ButtonPrimary>
      </Box>
      <Box
        component="div"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography fontSize={14} color="text.primary">
          Don't have an account?
        </Typography>
        <Box component="div" onClick={handleRegisterClick}>
          <Typography
            sx={{
              fontSize: "14px",
              marginLeft: 1,
              color: "#377dff",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            Create
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
