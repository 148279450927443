import { Button } from "@mui/material";
import { styled } from "@mui/system";

const ButtonTransparent = styled(Button)({
  borderRadius: "4px",
  padding: "6px 12px",
  fontWeight: "400",
  fontFamily: "inherit",
  lineHeight: "unset",
  textTransform: "unset",
  color: "#000000",
  border: "1px solid #000000",
  "&:hover": {
    color: "#000000",
    border: "1px solid #000000",
  },
});
export default ButtonTransparent;
