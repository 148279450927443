import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const sectionTitles = [
  "Potential Patentable Claims",
  "Trademark usage and potential trademarks",
  "Copyright usage",
  "Trade secrets"
];

function CheckYourIps() {
  const location = useLocation();
  const navigate = useNavigate();
  
  const { businessCanvasData, formData, step } = location.state;

  const handleBack = () => {
    navigate("/", { state: { businessCanvasData, formData, step: step } });
  };
   const p3Sections = Object.keys(businessCanvasData).filter(key =>
    key.startsWith("p3_")
  );
 
  return (
    <Box component="div">
      {p3Sections.map((p3Section, index) => (
        <Box
          key={index}
          component="div"
          className="main-border"
          mt={6}
          sx={{
            padding: { xs: "0.5rem 8px", sm: "1rem 20px" },
            backgroundColor: "#fff",
            position: 'relative',
          }}
        >
          <Box
            component="h2"
            fontWeight={500}
            color="var(--main-content-text-color)"
            mb={2}
          >
            {sectionTitles[index]}
          </Box>
          <Box
            mt={2}
            component="div"
            display="flex"
            justifyContent="flex-start"
            sx={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{ __html: businessCanvasData[p3Section] }}
          ></Box>
        </Box>
      ))}
      <Button
        sx={{ margin: "30px 0", float: 'right', marginRight: "10px" }}
        variant="outlined"
        startIcon={<ArrowBackIosNewIcon />}
        onClick={handleBack}
      >
        Back
      </Button>
    </Box>
  );
}

export default CheckYourIps;
